import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import PageHeader from '../components/PageHeader';
import FeaturedBlogPosts from '../components/FeaturedBlogPosts';

const BlogAuthorPageTemplate = ({
  data: {
    datoCmsBlogAuthor: { seoMetaTags, title, biography, image },
    allDatoCmsBlog: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms seo={seoMetaTags} />
    <main>
      <PageHeader
        heading={title}
        text={biography}
        image={image}
        breadcrumbs={[{ slug: 'blog', text: 'Blog' }]}
        isBlogAuthor={true}
      />
      <FeaturedBlogPosts heading={`More by ${title}`} items={nodes} />
    </main>
  </Layout>
);

export const BlogAuthorPageTemplateQuery = graphql`
  query BlogAuthorPageTemplateQuery($id: String!) {
    datoCmsBlogAuthor(id: { eq: $id }) {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      biography
      image {
        gatsbyImageData(width: 260, height: 260)
        alt
      }
    }
    allDatoCmsBlog(
      sort: { fields: meta___firstPublishedAt, order: DESC }
      filter: { author: { id: { eq: $id } } }
      limit: 6
    ) {
      nodes {
        ...BlogCardFragment
        featuredImage {
          ...OtherBlogCardFeaturedImageFragment
        }
      }
    }
  }
`;

export default BlogAuthorPageTemplate;
